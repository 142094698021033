import React from "react";
import { Link } from "gatsby";
import logo from "../../static/logo.svg";

const Logo = ({ linkClass }) => (
  <Link id="siteTitle" className={linkClass} to="/">
    <img src={logo} class="max-h-16 inline" alt="logo" />
  </Link>
);

export default Logo;
