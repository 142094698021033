import React from "react";
import CTALink from "./CTALink";
import Logo from "./logo";
import styles from "./header.module.css";

const Header = ({
  showNav,
  scrolled,
  navMenuItems = [],
  textWhite = true,
  onShowNav,
  onHideNav
}) => {
  let headerClass = styles.nav + " fixed w-full z-30 top-0 text-white";
  headerClass += scrolled ? " bg-white shadow" : "";

  let navActionClass =
    "mx-auto lg:mx-0 hover:underline font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 no-underline";
  navActionClass += !textWhite || !scrolled ? " bg-white text-gray-800" : "";
  navActionClass += textWhite || scrolled ? " gradient text-white" : "";

  let navContentClass =
    "w-full flex-grow lg:flex lg:items-center lg:w-auto lg:block mt-2 lg:mt-0 p-4 lg:p-0 z-20 lg:text-base lg:bg-transparent bg-white";
  navContentClass += textWhite && !scrolled ? " text-white" : "";
  navContentClass += !textWhite || scrolled ? " text-gray-800" : "";

  let titleClass =
    "toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl lg:ml-4";
  titleClass += !textWhite || scrolled ? " text-gray-800" : "";
  titleClass += textWhite || !scrolled ? " text-white" : "";

  if (false === showNav) {
    navContentClass += " hidden";
  }

  return (
    <nav id="header" className={headerClass}>
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 p-4">
        <div className="flex items-center mx-auto lg:ml-0">
          <Logo linkClass={titleClass} />
        </div>

        <div class="block lg:hidden pr-4" onClick={showNav ? onHideNav : onShowNav}>
          <button
            id="nav-toggle"
            class="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-800 hover:border-teal-500 appearance-none focus:outline-none"
          >
            <svg
              class="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>
        {navMenuItems && (
          <div className={navContentClass} id="nav-content">
            <ul className="no-list list-none lg:flex justify-end flex-1 items-center">
              {navMenuItems.map(i => (
                <li className="mr-3">
                  <CTALink {...i} buttonActionClass={navActionClass} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
