import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

const SEO = ({ title, description, image, article, bodyAttr, lang }) => {
  const { site } = useStaticQuery(seoQuery);

  const seo = {
    title: title || site.openGraph.title || site.title || null,
    description: description || site.openGraph.description || null,
    image: image && image.asset ? imageUrlFor(buildImageObj(image)).width(1200).url() : null,
    author: null, // site.author || ""
    url: null,
  };

  return (
    <Helmet
      bodyAttributes={bodyAttr}
      htmlAttributes={{ lang }}
      title={seo.title}
      titleTemplate={seo.title === site.title ? seo.title : `%s | ${site.title}`}
      meta={[
        {
          name: "description",
          content: seo.description
        },
        seo.image ? {
          property: "image",
          content: seo.image
        } : {},
        {
          property: "og:title",
          content: seo.title
        },
        {
          property: "og:description",
          content: seo.description
        },
        {
          property: "og:type",
          content: "website"
        },
        seo.image ? {
          property: "og:image",
          content: seo.image
        } : {},
        seo.url ? {
          property: "og:url",
          content: seo.url
        } : {},
        article ? {
          property: "og:type",
          content: "article"
        } : {},
        {
          name: "twitter:card",
          content: seo.image ? "summary_large_image" : "summary"
        },
        seo.author ? {
          name: "twitter:creator",
          content: seo.author
        } : {},
        {
          name: "twitter:title",
          content: seo.title
        },
        {
          name: "twitter:description",
          content: seo.description
        },
        seo.image ? {
          name: "twitter:image",
          content: seo.image
        } : {}
      ]}
    >
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: "fr",
  title: null,
  description: null,
  image: null
};

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string
};

export default SEO;

const seoQuery = graphql`
  query SEO {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      openGraph {
        title
        description
      }
    }
  }
`;
