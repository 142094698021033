import React from "react";
import Helmet from "react-helmet";

const Favicons = () => {
  return (
    <Helmet
      link={[
        {
          rel: "manifest",
          href: "/favicons/manifest.json"
        },
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/favicons/favicon-180x180.png"
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "96x96",
          href: "/favicons/favicon-96x96.png"
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "/favicons/favicon-32x32.png"
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "/favicons/favicon-16x16.png"
        }
      ]}
      meta={[
        {
          name: "msapplication-config",
          content: "/favicons/browserconfig.xml"
        },
        {
          name: "msapplication-TileColor",
          content: "#ffffff"
        },
        {
          name: "theme-color",
          content: "#ffffff"
        }
      ]}
    >
    </Helmet>
  );
};

export default Favicons;