import React from "react";
import { FaGithub, FaSlack, FaTwitter } from 'react-icons/fa';

const fullYear = new Date().getFullYear()

const Footer = () => (
  <footer>
    <div className="container mx-auto px-8">
      <div className="w-full flex flex-col md:flex-row py-6">

        <div className="flex-1">
          <ul className="list-none mb-6 no-list flex justify-center">
            <li className="mt-2 inline-block mr-2 md:block md:mr-0 px-3">
              <a
                target="_blank"
                href="https://join.aixmarseille.tech"
                rel="noopener noreferrer"
                className="no-underline hover:underline text-gray-800 hover:text-accent text-3xl"
              >
                <FaSlack/>
              </a>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0 px-3">
              <a
                target="_blank"
                href="https://github.com/AixMarseille-tech"
                rel="noopener noreferrer"
                className="no-underline hover:underline text-gray-800 hover:text-accent text-3xl"
              >
                <FaGithub/>
              </a>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0 px-3">
              <a
                target="_blank"
                href="https://twitter.com/AixMrsTech"
                rel="noopener noreferrer"
                className="no-underline hover:underline text-gray-800 hover:text-accent text-3xl"
              >
                <FaTwitter/>
              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>
    <p class="text-gray-900 text-center text-sm pb-4">
      Collectif tech _AixMarseille - ©{fullYear} 
    </p>
  </footer>
);

export default Footer;
